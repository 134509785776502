
/* Team 1 ---------------------------------- */
.team-box {
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    .team-img {
        position: relative;
        overflow: hidden;
        border-radius: 11px;
        img {
            width: 100%;
            transition: 0.4s ease-in-out;
        }
    }
    .team-content {
        background-color: $white-color;
        position: absolute;
        bottom: -150px;
        left: 0;
        width: 100%;
        padding: 30px;
        visibility: hidden;
        opacity: 0;
        transition: 0.4s ease-in-out;
    }
    .team-title {
        font-size: 24px;
        margin-bottom: 0;
        margin-top: -0.23em;
        font-weight: 500;
        a {
            color: inherit;
            &:hover {
                color: $theme-color;
            }
        }
    }
    .team-desig {
        color: $theme-color;
        display: block;
        font-family: $title-font;
        margin-bottom: -0.45em;
    }
    .as-social {
        text-align: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 2;
        transition: 0.4s ease-in-out;
        visibility: hidden;
        opacity: 0;
    }
    &:hover {
        .team-img {
            img {
                filter: grayscale(100) blur(1px) brightness(0.5);
            }
        }
        .team-content {
            bottom: 0;
            visibility: visible;
            opacity: 1;
        }
        .as-social {
            top: 30%;
            visibility: visible;
            opacity: 1;
        }
    }
}

#team-sec {
    background-position: center bottom;
    border-radius: 15px;
    max-width: 1680px;
    margin-left: auto;
    margin-right: auto;
}

@include lg {
    .team-box {
        --space: 20px;
    }
}

/* Team 2 ---------------------------------- */
.team-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    .line {
        height: 16px;
        width: 1px;
        display: inline-block;
        background-color: #B3C1D3;
    }
    span {
        color: $title-color;
        i {
            color: $theme-color;
            margin-right: 8px;
        }
    }
}
.team-card {
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    background-color: $white-color;
    box-shadow: 0px 5px 18px rgba(76, 88, 104, 0.06);
    .team-img {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 35px));
        img {
            width: 100%;
            transition: 0.4s ease-in-out;
        }
    }
    .team-title {
        font-size: 24px;
        margin-bottom: 0;
        margin-top: -0.23em;
        font-weight: 600;
        a {
            color: inherit;
            &:hover {
                color: $theme-color;
            }
        }
    }
    .team-desig {
        color: $theme-color;
        display: block;
        font-family: $title-font;
        margin-bottom: -0.45em;
    }
    .team-content {
        background-color: $white-color;
        padding: 20px 30px 30px 30px;
    }
    .team-info {
        padding: 23px 30px;
        border-top: 1px solid #ECF1F9;
    }
    .icon-btn {
        background-color: $theme-color;
        color: $white-color;
        position: relative;
        z-index: 3;
        &:hover {
            i {
                transition: 0.3s;
                &:before {
                    content: "\f068";
                }
            }
        }
    }
    .as-social {
        position: absolute;
        width: 45px;
        bottom: 50px;
        left: 2.5px;
        a {
            margin-right: 0;
            margin-bottom: 5px;
            background-color: $white-color;
            color: $theme-color;
            box-shadow: 0px 5px 18px rgba(76, 88, 104, 0.06);

            visibility: hidden;
            opacity: 0;
            transform: translateY(45px);
            transition: 0.3s ease-in-out;
            &:nth-child(1) {
                transition-delay: 0s;
            }
            &:nth-child(2) {
                transition-delay: 0.1s;
            }
            &:nth-child(3) {
                transition-delay: 0.2s;
            }
            &:nth-child(4) {
                transition-delay: 0.3s;
            }
            &:hover {
                background-color: $theme-color;
                color: $white-color;
            }
        }
    }
    .team-social {
        position: absolute;
        bottom: 175px;
        left: 30px;
        &:hover {
            .as-social {
                a {
                    visibility: visible;
                    opacity: 1;
                    transform: translateY(0);
                    &:nth-child(4) {
                        transition-delay: 0s;
                    }
                    &:nth-child(3) {
                        transition-delay: 0.1s;
                    }
                    &:nth-child(2) {
                        transition-delay: 0.2s;
                    }
                    &:nth-child(1) {
                        transition-delay: 0.3s;
                    }
                }
            }
        }
    }
    &:hover {
        .team-img {
            img {
                transform: scale(1.08);
            }
        }
    }
    &.style2 {
        .team-social {
            bottom: 100px;
        }
    }
}

/* Team 3 ---------------------------------- */
.team-grid {
    position: relative;
    overflow: hidden;
    background-color: $white-color;
    border: 1px solid #ECF1F9;
    box-shadow: 0px 9px 14px #FBFBFB;
    border-radius: 10px;
    .team-img {
        margin: -1px;
        overflow: hidden;
        position: relative;
        img {
            width: 100%;
            transition: 0.4s ease-in-out;
        }
        &:before {
            content: '';
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(5, 10, 16, 0.0728) 55.88%, rgba(8, 18, 31, 0.91) 100%);
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            z-index: 1;
        }
    }
    .team-content {
        text-align: center;
        position: absolute;
        bottom: 72px;
        left: 0;
        width: 100%;
        padding: 40px 15px;
        z-index: 3;
    }
    .team-title {
        color: $white-color;
        font-size: 24px;
        margin-bottom: 0;
        margin-top: -0.23em;
        font-weight: 600;
        a {
            color: inherit;
            &:hover {
                color: $theme-color;
            }
        }
    }
    .team-desig {
        color: $white-color;
        display: block;
        font-family: $title-font;
        margin-bottom: -0.45em;
    }
    .team-info {
        padding: 23px 40px;
    }
    .icon-btn {
        background-color: $theme-color;
        color: $white-color;
        position: relative;
        z-index: 3;
        &:hover {
            i {
                transition: 0.3s;
                &:before {
                    content: "\f068";
                }
            }
        }
    }
    &:hover {
        .team-img {
            img {
                transform: scale(1.08);
            }
        }
    }
}

@include ml {
    .team-grid {
        .team-info {
            padding: 20px 25px;
        }
        .team-content {
            padding: 30px 15px;
            bottom: 66px;
        }
    }
}

@include sm {
    .team-grid .team-info {
        justify-content: space-around;
    }
}

@media (max-width: 430px) {
    .team-grid .team-info {
        justify-content: space-between;
    }
}

/* Team Details ---------------------------------- */
.team-details-img {
    border-radius: 10px;
    overflow: hidden;
    margin-right: 20px;
}
.about-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .as-social {
        a {
            border: 1px solid #DBE3EE;
            color: $body-color;
            border-radius: 3px;
            --icon-size: 34px;
            font-size: 13px;
            &:hover {
                color: $white-color;
                border-color: $theme-color;
            }
        }
    }
}

.about-info {
    .title {
        font-size: 40px;
        margin-bottom: 5px;
        margin-top: -0.2em;
    }
    .desig {
        display: block;
        color: $theme-color;
    }
}
.social-box {
    display: flex;
    align-items: center;
    gap: 15px;
    .title {
        font-weight: 500;
        color: $title-color;
    }
}
.about-quality {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
    border-top: 1px dashed;
    border-bottom: 1px dashed;
    border-color: #E0E0E0;
    margin-top: 38px;
}
.quality-box {
    .quality-text {
        display: block;
        i {
            color: $theme-color;
            margin-right: 10px;
        }
        a {
            color: $body-color;
            &:hover {
                color: $theme-color;
            }
        }
    }
}

@include lg {
    .team-details-img {
        margin-right: 0;
        margin-bottom: 40px;
    }
}

@include md {
    .about-info .title {
        font-size: 36px;
    }
}

@include sm {
    .about-quality {
        padding-top: 20px;
        padding-bottom: 20px;
        flex-wrap: wrap;
        gap: 15px;
    }
    .quality-box {
        min-width: calc(50% - 8px);
    }
    .about-info .title {
        font-size: 32px;
    }
    .about-box {
        flex-wrap: wrap;
        gap: 15px 5px;
    }
}
@include xs {
    .about-info .title {
        font-size: 28px;
        margin-bottom: 2px;
    }
}