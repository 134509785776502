.cta-content {
    padding: 80px 0 80px 80px;
}
.cta-wrap {
    background-color: $white-color;
    position: relative;
    z-index: 3;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 8px 40px rgba(15, 27, 49, 0.12);
}

.cta-img {
    height: 100%;
    img {
        height: 100%;
        width: 100%;
    }
}

@include lg {
    .cta-content {
        padding: 40px 0 40px 20px;
    }
}

// Cta 2
.cta-card {
    padding: 60px;
    border-radius: 10px;
    overflow: hidden;
}
@include xs {
    .cta-card {
        padding: 45px 15px;
    }
}