/* Process 1 ---------------------------------- */ 
.process-card {
    &-wrap {
        border-right: 1px dashed #D0D7E3;
        margin-bottom: 40px;
        &:last-child {
            border-right: none;
        }
    }
    text-align: center;
    max-width: 290px;
    margin: 0 auto 0 auto;
    &_img {
        margin-bottom: 25px;
        img {
            border-radius: 999px;
        }
    }
    &_title {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 15px;
    }
    &_text {
        margin-bottom: -0.5em;
    }
}

@include md {
    .process-card {
        &-wrap {
            &:nth-child(2n) {
                border-right: none;
            }
        }
        &_img {
            margin-bottom: 20px;
        }
    }
}