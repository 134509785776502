.widget {
    padding: var(--blog-space-y, 40px) var(--blog-space-x, 40px);
    border: 1px solid $border-color;
    box-shadow: 0px 9px 14px #FBFBFB;
    border-radius: 10px;
    margin-bottom: 40px;
    position: relative;
    select,
    input {
        height: 55px;
        border-radius: 8px;
    }
}

.wp-block-search__label,
.widget_title {
    position: relative;
    font-size: 24px;
    font-weight: 600;
    font-family: $title-font;
    line-height: 1em;
    margin: -0.1em 0 29px 0;
    padding-bottom: 16px;
    border-bottom: 1px dashed #D3DBEB;
    a {
        color: inherit;
    }
}

.widget {
    .search-form {
        display: flex;

        input {
            flex: 1;
            padding-right: 8px;
            border-radius: 8px 0 0 8px;
        }

        button {
            border: none;
            width: 55px;
            height: 55px;
            line-height: 55px;
            background-color: $theme-color;
            color: $white-color;
            display: inline-block;
            border-radius: 0 8px 8px 0;

            &:hover {
                background-color: $title-color;
                color: $white-color;
            }
        }
    }
}

.wp-block-tag-cloud,
.tagcloud {
    a {
        display: inline-block;
        border: none;
        line-height: 1;
        padding: 9.5px 16px;
        margin-right: 5px;
        margin-bottom: 10px;
        text-transform: capitalize;
        color: $body-color;
        background-color: $smoke-color;
        border-radius: 5px;

        &:hover {
            background-color: $theme-color;
            color: $white-color !important;
        }
    }
}

.tagcloud {
    margin-right: -10px;
    margin-bottom: -10px;
}

.recent-post {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    line-height: 20px;
    &:last-child {
        margin-bottom: 0;
    }

    .media-img {
        margin-right: 20px;
        width: 80px;
        border-radius: 5px;
        overflow: hidden;

        img {
            width: 100%;
            transition: 0.4s ease-in-out;
        }
    }

    .post-title {
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        margin: 0 0 -0.1em 0;
        font-family: $title-font;
    }

    .recent-post-meta {
        margin-bottom: 5px;

        a {
            text-transform: capitalize;
            color: $body-color;
            &:hover {
                color: $theme-color;
            }
            i {
                margin-right: 8px;
                color: $theme-color;
            }
        }
    }
    &:hover {
        .media-img {
            img {
                transform: scale(1.1);
            }
        }
    }
}

.sidebar-gallery {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;

    .gallery-thumb {
        overflow: hidden;
        border-radius: 6px;
        img {
            width: 100%;
        }
    }
}

.vs-video-widget {
    .video-thumb {
        position: relative;
    }

    .play-btn {
        --icon-size: 60px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        i {
            font-size: 16px;
        }
    }

    .video-thumb-title {
        font-size: 20px;
        font-weight: 600;
        line-height: 1.4;
        margin-top: 10px;
        margin-bottom: -0.3em;
    }
}

.widget_recent_entries {
    ul {
        margin: -0.3em 0 0 0;
        padding: 0;
        list-style: none;

        li {
            > a {
                color: $body-color;
                font-weight: 500;
                display: inline-block;

                &:hover {
                    color: $theme-color;
                }
            }

            span.post-date {
                font-size: 14px;
            }

            &:not(:last-child) {
                border-bottom: 1px solid #dadada;
                padding-bottom: 12px;
                margin-bottom: 12px;
            }
        }
    }
}
.widget_recent_comments,
.wp-block-latest-comments {
    ul {
        list-style-type: none;
        padding-left: 0;
    }
}
ul.widget_recent_comments,
ol.widget_recent_comments,
.wp-block-latest-comments {
    margin-top: -0.11em;
    padding-left: 0;
}

.widget_recent_comments,
.wp-block-latest-comments {
    ol,
    ul {
        margin-bottom: 0;
    }
    li {
        margin-bottom: 0;
        color: $body-color;
        padding-left: 30px;
        position: relative;
        &:before {
            content: "\f086";
            position: absolute;
            left: 0;
            top: -1px;
            color: var(--theme-color);
            font-family: var(--icon-font);
        }
    }
    &.has-avatars {
        li {
            padding-left: 0;
            padding-bottom: 0 !important;
            &:before {
                display: none;
            }
        }
    }
    .avatar {
        margin-top: 0.4em;
    }
    li:not(:last-child) {
        padding-bottom: 12px;
    }
    article {
        line-height: 1.5;
    }
    a {
        color: inherit;
        &:hover {
            color: $theme-color;
        }
    }
}
.wp-block-latest-comments__comment {
    line-height: 1.6;
    a {
        color: $body-color;
        &:hover {
            color: $theme-color;
        }
    }
    &:last-child {
        margin-bottom: 0;
    }
}

.wp-block-calendar tbody td,
.wp-block-calendar th {
    padding: 10px;
}

.wp-block-calendar,
.calendar_wrap {
    position: relative;
    background-color: #fff;
    padding-bottom: 0;
    border: none;

    span[class*="wp-calendar-nav"] {
        position: absolute;
        top: 9px;
        left: 20px;
        font-size: 14px;
        color: $white-color;
        font-weight: 400;
        z-index: 1;
        line-height: 1.7;

        a {
            color: inherit;
        }
    }

    span.wp-calendar-nav-next {
        left: auto;
        right: 20px;
    }

    caption {
        caption-side: top;
        text-align: center;
        color: $white-color;
        background-color: $theme-color;
    }

    th {
        font-size: 14px;
        padding: 5px 5px;
        border: none;
        text-align: center;
        border-right: 1px solid #fff;
        color: $title-color;
        font-weight: 500;
        &:first-child {
            border-left: 1px solid #eee;
        }
        &:last-child {
            border-right: 1px solid #eee;
        }
    }
    table {
        th {
            font-weight: 500;
        }
    }

    td {
        font-size: 14px;
        padding: 5px 5px;
        color: #01133c;
        border: 1px solid #eee;
        text-align: center;
        background-color: transparent;
        transition: all ease 0.4s;
    }

    #today {
        color: $theme-color;
        background-color: $white-color;
        border-color: #ededed;
    }

    thead {
        background-color: #eee;
    }

    .wp-calendar-table {
        margin-bottom: 0;
    }

    .wp-calendar-nav {
        .pad {
            display: none;
        }
    }

    a {
        color: inherit;
        text-decoration: none;
        &:hover {
            color: $title-color;
        }
    }
}

.wp-block-calendar {
    margin-bottom: 30px;
    border: none;
    padding-bottom: 0;

    table {
        caption {
            color: $white-color;
        }
    }
}
ul,
ol {
    &.widget_rss,
    &.wp-block-rss {
        padding-left: 0;
    }
}
.widget_rss,
.wp-block-rss {
    list-style-type: none;
    ul {
        margin: -0.2em 0 -0.5em 0;
        padding: 0;
        list-style: none;

        .rsswidget {
            color: var(--title-color);
            font-family: var(--theme-font);
            font-size: 18px;
            display: block;
            margin-bottom: 10px;
        }

        .rssSummary {
            font-size: 14px;
            margin-bottom: 7px;
            line-height: 1.5;
        }

        a {
            display: block;
            font-weight: 600;
            color: inherit;

            &:hover {
                color: $theme-color;
            }
        }

        .rss-date {
            font-size: 14px;
            display: inline-block;
            margin-bottom: 5px;
            font-weight: 400;
            color: $title-color;

            &:before {
                content: "\f073";
                font-family: $icon-font;
                margin-right: 10px;
                font-weight: 300;
                color: $theme-color;
            }
        }

        cite {
            font-weight: 500;
            color: $title-color;
            font-family: $body-font;
            font-size: 14px;

            &:before {
                content: "";
                position: relative;
                top: -2px;
                left: 0;
                width: 20px;
                height: 2px;
                display: inline-block;
                vertical-align: middle;
                margin-right: 8px;
                background-color: $theme-color;
            }
        }
    }

    li:not(:last-child) {
        margin-bottom: 16px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        padding-bottom: 16px;
    }
    a {
        &:hover {
            color: $theme-color;
        }
    }
}

.textwidget {
    margin-top: -0.1em;
}

.widget_shopping_cart {
    .widget_title {
        margin-bottom: 30px;
        border-bottom: none;
    }
    ul {
        margin: 0;
        padding: 0;

        li {
            list-style-type: none;
        }
    }

    .mini_cart_item {
        position: relative;
        padding: 30px 10px 30px 90px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        margin-bottom: 0;
        text-align: left;

        &:first-child {
            border-top: 1px solid rgba(0, 0, 0, 0.1);
        }
    }

    .cart_list {
        a:not(.remove) {
            display: block;
            color: $body-color;
            font-size: 16px;
            font-weight: 500;
            font-family: $title-font;
            font-weight: 600;
            color: $title-color;

            &:hover {
                color: $theme-color;
            }
        }

        a.remove {
            position: absolute;
            top: 50%;
            left: 95%;
            transform: translateY(-50%);
            color: $body-color;

            &:hover {
                color: $theme-color;
            }
        }

        img {
            width: 75px;
            height: 75px;
            position: absolute;
            left: 0;
            top: 20px;
            border: 1px solid $border-color;
        }
    }

    .quantity {
        display: inline-flex;
        white-space: nowrap;
        vertical-align: top;
        margin-right: 20px;
        font-size: 14px;
        color: $theme-color;
    }

    .total {
        margin-top: 20px;
        font-size: 18px;
        color: $title-color;
        font-family: $body-font;
    }

    .amount {
        padding-left: 5px;
    }

    .as-btn {
        margin-right: 15px;
        &:last-child {
            margin-right: 0;
        }
    }
}

.sidebar-area {
    .widget_shopping_cart .as-btn {
        margin-right: 10px;
        padding: 8px 22px;
        font-size: 14px;
    }
}
.sidebar-area {
    margin-bottom: -10px;
    .as-video {
        padding: 0;
        box-shadow: none;
        border-radius: 5px;
        .play-btn {
            --icon-size: 50px;
            font-size: 15px;
            > i {
                background-color: $white-color;
                color: $theme-color;
            }
            &:after, 
            &:before {
                background-color: $white-color;
            }
        }
    }
    ul.wp-block-latest-posts {
        margin-bottom: 0;

        li {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .wp-block-tag-cloud,
    .tagcloud {
        a {
            border: none;
        }
    }

    .newsletter-form {
        button {
            width: 100%;
            text-transform: capitalize;
            font-size: 16px;
            font-weight: 400;
            height: 60px;
            margin-top: 10px;
        }
    }

    .widget .wp-block-search {
        margin-bottom: 0;
    }

    .wp-block-group__inner-container h2 {
        font-size: 20px;
        line-height: 1em;
        margin-bottom: 20px;
        margin-top: -0.07em;
    }

    ol.wp-block-latest-comments {
        padding: 0;
        margin: 0;

        li {
            line-height: 1.5;
            margin: 0 0 20px 0;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            padding-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
                padding-bottom: 0;
                border-bottom: none;
            }
        }
    }
}

.recent-product {
    display: flex;

    .product-title {
        font-size: 16px;
    }
}

// Price Filter --------------
// .price_slider_wrapper {
//     margin-top: -0.5em;
//     .price_label {
//         span {
//             display: inline-block;
//             color: $body-color;
//         }
//     }

//     .ui-slider {
//         height: 4px;
//         position: relative;
//         width: 100%;
//         background-color: #e0e0e0;
//         border: none;
//         margin-top: 10px;
//         margin-bottom: 20px;
//         cursor: pointer;
//         border-radius: 0;
//     }

//     .ui-slider-range {
//         border: none;
//         cursor: pointer;
//         position: absolute;
//         top: 0;
//         height: 100%;
//         z-index: 1;
//         display: block;
//         background-color: $theme-color;
//     }

//     .ui-slider-handle {
//         width: 10px;
//         height: 10px;
//         border-radius: 50%;
//         text-align: center;
//         line-height: 10.5px;
//         padding: 0;
//         border: none;
//         cursor: pointer;
//         position: absolute;
//         margin-top: -3px;
//         z-index: 2;
//         box-shadow: 0px 8px 13px 0px rgba(255, 79, 38, 0.21);
//         background-color: $theme-color;
//         transform: translateX(-1px);

//         &:focus {
//             outline: none;
//             box-shadow: 1.5px 2.598px 10px 0px rgba(0, 0, 0, 0.15);
//         }

//         // &:before {
//         //     content: "";
//         //     position: absolute;
//         //     background-color: #fff;
//         //     top: 50%;
//         //     left: 50%;
//         //     width: 12px;
//         //     height: 12px;
//         //     border-radius: 50%;
//         //     transform: translate(-50%, -50%);
//         // }
//         &:last-child {
//             transform: translateX(-9px);
//         }
//     }
//     button,
//     .button {
//         background-color: $theme-color;
//         color: $white-color;
//         font-weight: 500;
//         line-height: 1.6;
//         text-transform: capitalize;
//         text-align: center;
//         border-radius: 50px;
//         border: none;
//         display: inline-block;
//         overflow: hidden;
//         position: relative;
//         z-index: 2;
//         padding: 7px 20px;
//         min-width: 100px;
//         font-size: 16px;
//         transition: 0.4s ease-in;
//         &:hover {
//             background-color: $title-color;
//         }
//     }
// }

// // Popular products ----------------
// .product_list_widget {
//     list-style: none;
//     padding-left: 0;
//     margin-bottom: 0;

//     .recent-post {
//         display: flex;
//         align-items: center;
//         margin-bottom: 20px;
//         padding-bottom: 0;
//         border-bottom: none;
//         &:last-child {
//             margin-bottom: 0;
//         }
//         .media-img {
//             width: 70px;
//             margin-right: 20px;
//         }
//     }
//     .recent-post-title {
//         font-size: 20px;
//         margin-bottom: 7px;
//         a {
//             color: inherit;
//             &:hover {
//                 color: $theme-color;
//             }
//         }
//     }
//     .star-rating {
//         font-size: 12px;
//         margin-bottom: 3px;
//     }
// }

// Widget Info ---------------------
.widget_info {
    .widget_title {
        margin: 29px 0 0 0;
    }
    .as-btn {
        width: 100%;
        margin-bottom: 10px;
        display: block;
    }
    .as-video {
        margin-bottom: 20px;
    }
    .course-price {
        display: block;
        font-weight: bold;
        margin-bottom: 19px;
        .tag {
            display: inline-block;
            background-color: $theme-color2;
            font-size: 12px;
            font-weight: 500;
            text-transform: uppercase;
            color: $white-color;
            border-radius: 99px;
            padding: 2px 13px;
            vertical-align: middle;
            margin-bottom: 5px;
        }
    }
}
.info-list {
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }
    i {
        color: $theme-color;
        width: 16px;
        margin-right: 2px;
        font-size: 16px;
    }
    strong {
        font-weight: 500;
        color: $title-color;
    }
    li {
        border-bottom: 1px dashed #D3DBEB;
        padding: 12px 0;
        &:last-child {
            border-bottom: none;
            padding-bottom: 0;
            margin-bottom: -0.45em;
        }
    }
}

// Widget map -------------------------
.widget_banner {
    padding: 0 !important;
    border: none;
}

.widget-map {
    line-height: 1px;
    border-radius: 10px;
    iframe {
        border-radius: 10px;
        height: 327px;
    }
}

@include lg {
    .recent-post {
        .post-title {
            font-size: 18px;
            line-height: 24px;
        }
    }
    .widget_offer {
        padding-top: 40px;
        padding-bottom: 40px;
    }
    .col-lg-4 {
        .sidebar-area {
            .widget {
                --blog-space-y: 40px;
                --blog-space-x: 20px;
            }
        }
    }
}

@include md {
    .sidebar-area {
        padding-top: 30px;
    }

    .wp-block-tag-cloud a,
    .tagcloud a {
        padding: 10.5px 18px;
    }
    .col-lg-4 {
        .sidebar-area {
            .widget {
                --blog-space-y: 40px;
                --blog-space-x: 40px;
            }
        }
    }
}

@include sm {
    .widget {
        --blog-space-y: 40px;
        --blog-space-x: 20px;
    }
    .col-lg-4 {
        .sidebar-area {
            .widget {
                --blog-space-y: 40px;
                --blog-space-x: 20px;
            }
        }
    }
}