.footer-wrapper {
    position: relative;
    z-index: 2;
    .newsletter-title {
        color: $white-color;
    }
    .as-social {
        a {
            background-color: #1e2c46;
            color: $white-color;
            &:hover {
                background-color: $theme-color;
				border-color: $theme-color;
            }
        }
    }
}
.widget-area {
    padding-top: $space;
    padding-bottom: 80px;
}

.copyright-wrap {
    padding: 29px 0;
}

.copyright-text {
    margin: 0;
    a {
        color: inherit;
        &:hover {
            color: $white-color;
        }
    }
}

@include ml {
    .as-social a {
        margin-right: 3px;
        &:last-child {
            margin-right: 0;
        }
    }
}

.footer-links {
    ul {
        padding: 0;
        margin: 0;
    }
    li {
        font-family: $body-font;
        display: inline-block;
        margin-right: 20px;
        &:last-child {
            margin-right: 0;
        }
    }
    a {
        font-family: inherit;
        color: $body-color;
        &:hover {
            color: $white-color;
        }
    }
}

.footer-layout1 {
    background-color: #0f1b31;
    --body-color: #b3c1d3;
    overflow: hidden;
    .copyright-wrap {
        padding: 22px 0;
        border-top: 1px solid #283752;
    }
}

@include lg {
    .footer-layout1 .copyright-wrap {
        padding: 25px 0;
    }
}

@include md {
    .footer-layout1 {
        .widget-area {
            padding-top: $space-mobile;
            padding-bottom: 38px;
        }
    }
    .copyright-text {
        text-align: center;
    }
}

/* Footer 2 ---------------------------------- */
.footer-layout2 {
    background-color: #0f1b31;
    --body-color: #b3c1d3;
    overflow-x: hidden;
    .widget-area {
        padding-top: 100px;
        padding-bottom: 55px;
    }
    .copyright-wrap {
        padding: 26px 0;
        background-color: $theme-color;
    }
    .copyright-text {
        text-align: center;
        color: $white-color;
    }
    .footer-links a {
        color: $white-color;
    }
    .as-widget-about .about-logo {
        margin-top: 0;
    }
    .scroll-btn {
        position: absolute;
        right: 50%;
        bottom: 53px;
        transform: translateX(50%);
        &:before {
            background-color: #0f1b31;
        }
        &:hover {
            i {
                background-color: $white-color;
                color: $theme-color;
            }
        }
    }
}

@include md {
    .footer-layout2 {
        .widget-area {
            padding-top: 80px;
            padding-bottom: 40px;
        }
    }
}

@include sm {
    .footer-layout2 .scroll-btn {
        bottom: 62px;
    }
}

@include xs {
    .footer-layout2 .scroll-btn {
        bottom: 87px;
    }
}

/* Footer 3 ---------------------------------- */
.footer-layout3 {
    --body-color: #fff;
    --border-color: #224d9c;
    .widget-area {
        padding-top: 100px;
        padding-bottom: 55px;
        padding-left: 66px;
    }
	.footer-top {
		padding-top: 80px;
		padding-bottom: 80px;
		border-bottom: 1px solid $border-color;
	}
    .copyright-wrap {
        padding: 22px 0;
        border-top: 1px solid $theme-color;
    }
    .copyright-text {
        text-align: center;
        color: $white-color;
    }
    .footer-links a {
        color: $white-color;
		&:hover {
			color: $theme-color;
		}
    }
	.as-social a {
		--icon-size: 40px;
		font-size: 13px;
		background-color: $theme-color;
		border: 1px solid $theme-color;
		margin-right: 1px;
	}
	.footer-info-title {
		font-size: 18px;
		font-weight: 600;
		text-transform: uppercase;
	}
    .widget_meta,
    .widget_pages,
    .widget_archive,
    .widget_categories,
    .widget_nav_menu {
        a {
            &:hover {
				color: $theme-color;
				&:before {
					color: $theme-color;
					transform: translateX(4px);
				}
			}
        }
    }
}
.about-area {
    padding-top: 100px;
    padding-bottom: 60px;
	padding-right: 90px;
	border-right: 1px solid $theme-color;
}
.info-box {
	&-wrap {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		gap: 30px;
		margin-bottom: 45px;
	}
	display: inline-block;
	&_text {
		display: block;
		color: $white-color;
		margin-top: -0.45em;
		margin-bottom: 5px;
	}
	&_link {
		font-size: 22px;
		font-weight: 600;
		color: $white-color;
        i {
            margin-right: 8px;
        }
		&:hover {
			color: $theme-color;
		}
	}
}
.download-btn {
	display: flex;
	align-items: center;
	gap: 10px;
	background-color: #193F85;
	border: 1px solid $border-color;
	padding: 13px 33px;
	border-radius: 999px;
	transition: 0.4s ease-in-out;
	i {
		font-size: 30px;
		color: $white-color;
	}
	.text {
		color: #85A4DD;
		font-size: 12px;
		line-height: 1;
		display: block;
		transition: 0.4s ease-in-out;
	}
	.store-name {
		font-size: 16px;
		color: $white-color;
		font-weight: 500;
		display: block;
		margin-bottom: -0.3em;
	}
	&:hover {
		background-color: $theme-color;
		border-color: $theme-color;
		.text {
			color: $white-color;
		}
	}
}

.newsletter-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
	gap: 10px 20px;
    .newsletter-title {
        margin-bottom: 0;
        max-width: 285px;
    }
}
.newsletter-form {
    display: flex;
    width: 100%;
    max-width: 680px;
	.form-group {
		margin-bottom: 0;
		width: 100%;
		> i {
			top: 19px;
			color: $white-color;
		}
	}
    input {
        background-color: #052A6F;
        border: 1px solid #2A4F93;
		margin-bottom: 0;
		height: 55px;
		color: $white-color;
		border-radius: 999px;
		@include inputPlaceholder {
			color: $white-color;
		}
        &:focus {
            border-color: $theme-color;
			background-color: #052A6F;
        }
    }
    .as-btn {
        min-width: 200px;
		border-radius: 999px;
		box-shadow: none;
		margin-left: -50px;
    }
}

@include ml {
	.download-btn {
		padding: 10px 26px;
	}
}

@include lg {
	.footer-top {
		.footer-logo {
			text-align: center;
			margin-bottom: 20px;
		}
	}
	.about-area {
		border-right: none;
		border-bottom: 1px solid $border-color;
		padding-top: 80px;
		padding-bottom: 40px;
	}
	.info-box-wrap {
		justify-content: flex-start;
		gap: 25px 60px;
	}
	.footer-layout3 .widget-area {
		padding-top: 80px;
		padding-bottom: 43px;
		padding-left: 0;
	}
}

@include sm {
	.newsletter-wrap {
		flex-wrap: wrap;
		justify-content: center;
		.newsletter-title {
			text-align: center;
			max-width: 100%;
		}
	}
}

@include xs {
	.newsletter-form {
		flex-wrap: wrap;
		justify-content: center;
		gap: 15px;
		.as-btn {
			margin-left: 0;
		}
	}
}

/* Footer 4 ---------------------------------- */
.footer-social {
    display: flex;
    gap: 20px;
    margin-top: 22px;
    a {
        color: $white-color;
        &:hover {
            color: $theme-color;
        }
    }
}
.footer-layout4 {
    --body-color: #fff;
    --border-color: #254684;
    background-color: #0D5EF4;
    .widget-area {
        padding-top: 120px;
        padding-bottom: 80px;
    }
    .footer-widget.style2 .widget_title {
        font-size: 20px;
    }
    .copyright-wrap {
        border-top: 1px solid $theme-color;
    }
    .copyright-text {
        color: $white-color;
    }
    .widget_meta,
    .widget_pages,
    .widget_archive,
    .widget_categories,
    .widget_nav_menu {
        a {
            &:hover {
				color: $theme-color;
				&:before {
					color: $theme-color;
					transform: translateX(4px);
				}
			}
        }
    }
}

@include md {
    .footer-layout4 {
        .widget-area {
            padding-top: 80px;
            padding-bottom: 40px;
        }
    }
}
