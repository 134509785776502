.blog {
  margin-top: 30px;

  .search {
    .form-control {
      box-shadow: none;
    }
  }

  .item {
    border-radius: 20px;
    box-shadow: 0 12px 48px rgba(0, 0, 0, 0.06);
    display: flex;
    flex-direction: column;

    .post-content {
      .post-image {
        img {
          width: 100%;
          height: 240px;
          border-top-left-radius: 20px;
          border-top-right-radius: 20px;
        }
      }

      .post-text {
        padding-right: 20px;
        padding-left: 20px;
        padding-top: 20px;
        padding-bottom: 20px;

        h4 {
          a {
            color: #0e101a;
            font-size: 24px;
            line-height: 140%;
            font-weight: 700;
            text-decoration: none;

            &:hover {
              color: #fa0;
            }
          }
        }

        .btn-primary {
          display: inline-block;
          margin-top: 25px;
          padding: 17px 45px;
          border: 2px solid #92abc9;
          border-radius: 6px;
          color: #92abc9;
          line-height: 120%;
          font-weight: 500;
          background-color: #fff;

          &:hover {
            color: #fa0;
            border: solid 2px #fa0;
          }
        }
      }
    }
  }
}
