.pricing__box {
  box-shadow: 0px 16px 32px 0px rgba(23, 39, 187, 0.06);
  padding: 65px 50px 50px 50px;
  border-radius: 8px;

  .tp-price-box__info {
    margin-bottom: 1.5rem;
    text-align: center;

    b {
      font-size: 80px;
      position: relative;
      padding-left: 25px;

      span {
       /* position: absolute;
        top: 0;
        left: 0;*/
        font-size: 40px;
        color: $theme-color;
      }
    }
  }

  &.catskill-white-bg {
    background-color: #f6f9fb;
  }
}
