/* Course 1 ---------------------------------- */
.course-title {
    font-size: 24px;
    font-weight: 500;
    line-height: 1.417;
    a {
        color: inherit;
        &:hover {
            color: $theme-color;
        }
    }
}
.course-img {
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    img {
        width: 100%;
        transition: 0.4s ease-in-out;
    }
    .tag {
        position: absolute;
        top: 20px;
        right: 20px;
        background-color: $theme-color2;
        color: $white-color;
        font-weight: bold;
        font-size: 14px;
        font-family: $body-font;
        padding: 0 17px;
        border-radius: 999px;
    }
}
.course-author {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    
}
.author-info {
    display: flex;
    align-items: center;
    gap: 10px;
    img {
        border-radius: 999px;
    }
    .author-name {
        font-size: 18px;
        color: $body-color;
        display: block;
        &:hover {
            color: $theme-color !important;
        }
    }
    .desig {
        display: block;
        font-size: 13px;
        color: $theme-color;
        line-height: 1;
    }
    .info {
        .author-name {
            color: $title-color;
            font-weight: 500;
            margin-top: -0.2em;
        }
    }
}
.course-rating {
    display: inline-flex !important;
    align-items: center;
    font-size: 12px;
    font-family: $body-font;
    gap: 3px;
    .star-rating {
        width: 75px;
        font-size: 11px;
    }
}

.course-meta {
    display: flex;
    align-items: center;
    justify-content: space-between;
    a, span {
        font-size: 12px;
        i {
            margin-right: 5px;
        }
    }
}

.course-box {
    border-radius: 10px;
    background-color: $white-color;
    transition: 0.4s ease-in-out;
    padding: var(--box-space-x, 30px) var(--box-space-x, 30px) 0  var(--box-space-x, 30px);
    overflow: hidden;
    .course-img {
        margin-bottom: 20px;
    }
    .course-title {
        margin-bottom: 21px;
    }
    .course-meta {
        padding-top: 20px;
        padding-bottom: 20px;
        border-top: 1px dashed #D0D7E3;
    }
    &:hover {
        &:hover {
            box-shadow: 0px 3px 0px #DFEAF4;
        }
        .course-img {
            img {
                transform: scale(1.1);
            }
        }
    }
    &.style4 {
        box-shadow: none;
        border: none;
        border: 1px solid #ECF1F9;
        box-shadow: 0px 9px 14px #FBFBFB;
    }
}

@include lg {
    .course-author .author-name {
        font-size: 16px;
    }
    .course-box {
        --box-space-x: 20px;
        .course-meta {
            padding-top: 12px;
            padding-bottom: 12px;
        }
        .course-title {
            margin-bottom: 15px;
        }
        &.style2 {
            .course-author {
                margin-bottom: 25px;
            }
        }
    }
}

@include sm {
    .course-box {
        --box-space-x: 30px;
        .course-meta {
            padding-top: 20px;
            padding-bottom: 20px;
        }
    }
}

@include vxs {
    .course-box {
        --box-space-x: 20px;
        .course-meta {
            padding-top: 12px;
            padding-bottom: 12px;
        }
    }
}

/* Course 2 ---------------------------------- */
.course-box {
    &.style2 {
        box-shadow: 0px 3px 0px #DFEAF4;
        .course-meta {
            a, span {
                font-size: 16px;
            }
        }
        .course-author {
            margin-bottom: 30px;
        }
    }
}
@include lg {
    .course-box {
        &.style2 {
            .course-author {
                margin-bottom: 25px;
            }
        }
    }
}

@include md {
    .course-box {
        &.style2 {
            .course-meta {
                a, span {
                    font-size: 14px;
                }
            }
        }
    }
}

@include vxs {
    .course-box {
        &.style2 {
            .course-title {
                font-size: 20px;
            }
            .author-info img {
                max-width: 35px;
            }
            .course-meta {
                a, span {
                    font-size: 12px;
                }
            }
        }
    }
}

/* Course 3 ---------------------------------- */
.course-box {
    &.style3 {
        background-color: $smoke-color;
        border-radius: 10px;
        padding: 0;
        .course-meta {
            a, span {
                font-size: 16px;
            }
        }
        .course-title {
            font-weight: 600;
        }
        .course-content {
            padding: 12px 40px 0 40px;
        }
        .course-author {
            margin-bottom: 30px;
        }
        .course-img {
            border-radius: 0;
        }
        .tag {
            top: 30px;
            right: 30px;
        }
        &:hover {
            box-shadow: none;
        }
    }
}

@include ml {
    .course-box {
        &.style3 {
            .course-content {
                padding: 2px 30px 0 30px;
            }
            .course-author {
                margin-bottom: 25px;
            }
        }
    }
}

@include lg {
    .course-box {
        &.style3 {
            .course-meta {
                padding-top: 18px;
                padding-bottom: 18px;
            }
        }
    }
}

@include md {
    .course-box {
        &.style3 {
            .course-meta {
                a, span {
                    font-size: 14px;
                }
            }
        }
    }
}

@include vxs {
    .course-box {
        &.style3 {
            .course-content {
                padding: 0px 20px 0 20px;
            }
            .course-title {
                font-size: 20px;
            }
            .author-info img {
                max-width: 35px;
            }
            .course-meta {
                padding-top: 12px;
                padding-bottom: 12px;
                a, span {
                    font-size: 12px;
                }
            }
        }
    }
}

/* Course 4 ---------------------------------- */
.course-sec {
    max-width: 1680px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    .bg-shape {
        position: absolute;
        top: 180px;
        left: 0;
        border-radius: 10px;
        height: calc(100% - 180px);
        width: 100%;
    }
}
.meta-box {
    display: flex;
    align-items: center;
    gap: 12px;
    i {
        @include equal-size-lineHeight(40px);
        text-align: center;
        font-size: 14px;
        background-color: $theme-color;
        color: $white-color;
        box-shadow: 0px 4px 18px rgba(13, 94, 244, 0.24);
        border-radius: 5px;
    }
    .text {
        font-size: 16px;
        color: $title-color;
        font-weight: 500;
        display: block;
        line-height: 1;
        margin-bottom: 6px;
    }
    .number {
        display: block;
        width: 100%;
        line-height: 1;
        font-size: 16px;
    }
}

.course-img {
    position: relative;
    .play-btn {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        &:after, 
        &:before {
            background-color: transparent;
            border: 1px solid $white-color;
        }
    }
}

.course-grid {
    display: flex;
    align-items: center;
    margin-bottom: 50px;
    position: relative;
    &:last-of-type {
        margin-bottom: 0;
    }
    .course-img {
        min-width: fit-content;
        width: 60%;
        position: relative;
        height: 392px;
        img {
            width: fit-content;
            height: 100%;
            object-fit: cover;
        }
        &::before {
            content: '';
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-color: #0D2139;
            opacity: 0.2;
        }
    }
    .course-content {
        background-color: $white-color;
        min-width: 50%;
        box-shadow: 0px 4px 29px rgba(152, 170, 204, 0.15);
        border-radius: 10px;
        padding: 70px;
        position: absolute;
        bottom: 50%;
        right: 0;
        z-index: 3;
        transform: translateY(50%);
    }
    .course-title {
        font-size: 30px;
        font-weight: 600;
        margin-top: -0.3em;
        margin-bottom: 2px;
    }
    .course-author {
        display: block;
        margin-bottom: 20px;
        padding-bottom: 12px;
        border-bottom: 1px dashed #D0D7E3;
    }
    &:nth-of-type(even) {
        flex-direction: row-reverse;
        .course-content {
            right: unset;
            left: 0;
        }
    }
}

@include ml {
    .course-grid {
        .course-img {
            min-width: auto;
            img {
                width: 100%;
            }
        }
    }
}

@include lg {
    .course-grid {
        .course-img {
            width: 45%;
        }
    }
}

@include md {
    .course-grid {
        .course-img {
            width: 80%;
        }
        .course-content {
            transform: translateY(0);
            bottom: 0;
            padding: 30px;
        }

    }
    .course-img .play-btn {
        transform: translate(-50%, -120%);
    }
}

@include sm {
    .course-grid {
        flex-direction: column !important;
        .course-img {
            width: 100%;
            height: 280px;
        }
        .course-content {
            bottom: unset;
            top: -20px;
            padding: 30px 15px;
            position: relative;
        }

    }
    .course-img .play-btn {
        transform: translate(-50%, -50%);
    }
}

@include xs {
    .course-meta {
        flex-wrap: wrap;
        gap: 10px;
    }
    .meta-box i {
        display: none;
    }
}

@include vxs {
    .course-grid .course-title {
        font-size: 24px;
        margin-bottom: 9px;
        line-height: 1.3;
    }
}

/* Course 5 ---------------------------------- */
.video-course {
    --box-space: 30px;
    text-align: center;
    .course-img {
        border-radius: 10px;
        overflow: hidden;
        position: relative;
        z-index: 2;
        &:before {
            content: '';
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #11213D 100%);
            z-index: 1;
        }
    }
    .play-btn {
        --icon-size: 70px;
        font-size: 1em;
        color: $white-color;
        transform: translate(-50%, -50%);
        > i {
            border: 2px solid;
            background-color: transparent;
            color: $white-color;
        }
        &:after,
        &:before {
            background-color: transparent;
            border: 2px solid;
        }
        &:hover {
            > i {
                color: $theme-color;
            }
            &:after,
            &:before {
                border-color: $theme-color;
            }
        }
    }
    .tag {
        top: 30px;
        right: 30px;
    }
    .course-content {
        max-width: calc(100% - var(--box-space)*2);
        margin-left: auto;
        margin-right: auto;
        background: $white-color;
        border: 1px solid #ECF1F9;
        box-shadow: 0px 9px 14px rgba(97, 94, 94, 0.06);
        border-radius: 10px;
        margin-top: -54px;
        padding: 30px 15px;
        position: relative;
        z-index: 3;
    }
    .course-title {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 3px;
        margin-top: -0.28em;
    }
    .author-name {
        display: block;
        margin-bottom: -0.45em;
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
    }
    &:hover {
        .course-img {
            img {
                transform: scale(1.08);
            }
        }
    }
}

/* Course Pages ---------------------------------- */
.course-result-count {
    font-size: 18px;
    font-weight: 500;
    color: $title-color;
    display: inline-block;
}
@include xs {
    .course-result-count {
        font-size: 16px;
    }
}

/* Course List ---------------------------------- */
.course-list {
    display: flex;
    align-items: center;
    gap: 40px;
    position: relative;
    padding: var(--blog-space-y, 40px) var(--blog-space-x, 40px);
    border: 1px solid $border-color;
    box-shadow: 0px 9px 14px #fbfbfb;
    border-radius: 10px;
    margin-bottom: 30px;
    .course-content {
        max-width: 730px;
    }
    .course-img {
        min-width: 530px;
        .tag {
            right: unset;
            left: 20px;
        }
    }
    .course-title {
        font-size: 30px;
        font-weight: 600;
        line-height: 1.333;
    }
    .course-meta {
        max-width: 560px;
    }
    .course-author {
        max-width: 360px;
        margin-bottom: 17px;
    }
    .course-text {
        margin-bottom: 20px;
    }
}

@include lg {
    .course-list {
        .course-img {
            display: none;
        }
    }
}

@include md {
    .course-list {
        .course-title {
            font-size: 24px;
            margin-bottom: 10px;
        }
        .course-author {
            max-width: 360px;
            margin-bottom: 10px;
        }
        .course-text {
            margin-bottom: 15px;
        }
    }
}
@include sm {
    .course-list {
        --blog-space-x: 20px;
        .course-author {
            flex-direction: row;
            flex-wrap: wrap;
            gap: 3px 10px;
        }
    }
}

/* Course Details ---------------------------------- */
.course-tags {
    a {
        display: inline-block;
        background-color: $theme-color;
        color: $white-color;
        border-radius: 999px;
        padding: 0 15px;
        font-size: 12px;
        font-weight: 500;
        text-transform: uppercase;
        &:hover {
            background-color: $theme-color2;
        }
    }
}
.course-single-meta {
    > span,
    > a,
    > div {
        display: inline-block;
        margin-right: 15px;
        padding-right: 20px;
        font-size: 16px;
        font-weight: 500;
        color: $title-color;
        position: relative;
        &:after {
            content: "";
            width: 1px;
            height: 20px;
            background-color: #D3DBEB;
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
        }

        &:last-child {
            margin-right: 0;
            padding-right: 0;
            &:after {
                display: none;
            }
        }

        i {
            margin-right: 10px;
            color: $theme-color;
        }
        img {
            margin-right: 10px;
            border-radius: 99px;
        }
    }

    a:hover {
        color: $theme-color;
    }
    .course-rating {
        top: 3px;
        .star-rating {
            width: 100px;
            font-size: 15px !important;
        }
    }
}

.course-tab {
    gap: 1px;
    position: relative;
    .nav-link {
        font-size: 14px;
        font-weight: 500;
        text-transform: uppercase;
        background-color: $smoke-color;
        padding: 20px 20px;
        color: $body-color;
        width: 100%;
        text-align: center;
        &:hover,
        &.active {
            color: $theme-color;
        }
        i {
            margin-right: 8px;
        }
    }
    .nav-item {
        flex: 1;
    }
    .indicator {
        position: absolute;
        top: 0;
        left: 0;
        height: 3px !important;
        background-color: $theme-color;
        transition: 0.4s ease-in-out;
    }
}
.author-meta {
    margin-bottom: 18px;
    a, span {
        color: $title-color;
        margin-right: 20px;
        font-weight: 500;
        i {
            margin-right: 8px;
            color: $theme-color;
        }
        &:hover {
            color: $theme-color;
        }
        &:last-child {
            margin-right: 0;
        }
    }
}
.course-description {
    padding: var(--blog-space-y, 35px) var(--blog-space-x, 40px);
    > p:last-of-type {
        margin-bottom: 0;
    }
}
.course-curriculam {
    padding: var(--blog-space-y, 40px) var(--blog-space-x, 40px);
}
.course-instructor {
    padding: 0 var(--blog-space-x, 40px) 0 var(--blog-space-x, 40px);
}
.course-Reviews {
    .as-comments-wrap {
        margin: var(--blog-space-y, 40px) var(--blog-space-x, 40px);
    }
    .as-comment-form {
        margin-top: var(--blog-space-y, 40px);
        margin-bottom: 0;
        padding: var(--blog-space-y, 40px) var(--blog-space-x, 40px);
        margin-left: -1px;
        margin-right: -1px;
    }
}

.course-single {
    &-top {
        position: relative;
        margin-bottom: var(--blog-space-y, 40px);
        padding: var(--blog-space-y, 40px) var(--blog-space-x, 40px);
        border: 1px solid $border-color;
        box-shadow: 0px 9px 14px #fbfbfb;
        border-radius: 10px;
    }
    .course-title {
        font-size: 36px;
        font-weight: 600;
        line-height: 1.278;
        margin-bottom: 28px;
    }
    .course-img {
        margin-bottom: var(--blog-space-y, 40px);
    }
    .course-tags {
        margin-bottom: 15px;
    }
    &-bottom {
        position: relative;
        margin-bottom: var(--blog-space-y, 40px);
        border: 1px solid $border-color;
        box-shadow: 0px 9px 14px #fbfbfb;
        border-radius: 10px;
        padding-bottom: 0;
        overflow: hidden;
    }
}

@include lg {
    .course-single {
        --blog-space-x: 20px;
        .course-title {
            font-size: 30px;
        }
    }
    .course-single-meta {
        .text-theme {
            display: none;
        }
        > a,
        > span, 
        > div {
            font-size: 14px;
            margin-right: 5px;
            padding-right: 10px;
        }
    }
    .course-author-box .auhtor-img {
        min-height: 100%;
        max-width: 200px;
    }
}

@include md {
    .course-single {
        --blog-space-x: 40px;
    }
}

@include sm {
    .course-single {
        --blog-space-x: 20px;
        .course-title {
            font-size: 24px;
            margin-bottom: 18px;
        }
        .course-img {
            margin-bottom: 25px;
            img {
                min-height: 230px;
                object-fit: cover;
            }
        }
    }
    .course-tab {
        .nav-link i {
            margin-right: 0;
            display: block;
        }
    }
    .course-single-meta{
        > a,
        > span, 
        > div {
            i {
                margin-right: 5px;
            }
            padding-right: 5px;
            &:after {
                display: none;
            }
        }
    }
    .course-author-box {
        flex-direction: column;
        .auhtor-img {
            min-height: 100%;
            max-width: 100%;
            img {
                width: 100%;
            }
        }
    }
}

@include xs {
    .course-tab {
        .nav-link {
            padding: 12px;
        }
    }
    .course-author-box .media-body {
        padding: 35px 20px 35px 20px;
    }
}

@media (max-width: 460px) {
    .course-tab .nav-item {
        flex-basis: 49%;
    }
}

@include vxs {
    .course-single .course-title {
        font-size: 20px;
    }
}