/* Brand 1 ---------------------------------- */
.brand-box {
    &-wrap {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        justify-content: space-between;
        gap: 40px 24px;
    }
    text-align: center;
}

@media (max-width: 430px) {
    .brand-box {
        &-wrap {
            grid-template-columns: repeat(3, 1fr);
            gap: 24px 24px;
            justify-content: center;
        }
    }    
}