
.as-sort-bar {
    padding: 0;
    margin: 0 0 34px 0;

    .row {
        --bs-gutter-x: 0;
        --bs-gutter-y: 15px;
    }

    select {
        height: 50px;
        border: 1px solid $border-color;
        width: fit-content;
        min-width: 250px;
        font-size: 16px;
        margin: 0;
        color: $body-color;
    }

    .woocommerce-result-count {
        margin-bottom: 0;
        color: $body-color;
    }

    .nav {
        a {
            display: inline-block;
            position: relative;
            font-family: $title-font;
            font-weight: 600;
            font-size: 16px;
            line-height: 1.3;
            text-transform: capitalize;
            color: $title-color;
            margin: 0 0 0 20px;
            padding-left: 20px;
            border-left: 1px solid #D9D9D9;
            &:first-child {
                margin-left: 0;
                padding-left: 0;
                border-left: none;
            }
            i {
                margin-right: 3px;
            }

            &.active,
            &:hover {
                color: $theme-color;
            }
        }
    }
}
.quantity {
    position: relative;
    display: inline-flex;
    vertical-align: middle;

    > .screen-reader-text {
        display: inline-block;
        font-weight: 600;
        color: $title-color;
        font-family: $title-font;
        margin: 0;
        align-self: center;
        margin-right: 10px;
    }

    .qty-btn,
    .qty-input {
        display: inline-block;
        width: 50px;
        height: 50px;
        border: 1px solid $border-color;
        border-right: none;
        background-color: transparent;
        padding: 0;
        border-radius: 0;
        text-align: center;
        color: $body-color;
        font-size: 18px;
        font-weight: 600;

        &:last-child {
            border-right: 1px solid $border-color;
        }
    }

    .qty-btn {
        font-size: 16px;
    }
}

@include lg {
    .as-sort-bar {
        select {
            min-width: auto;
        }
    }
}

@include md {
    .as-sort-bar {
        .row {
            --bs-gutter-x: 20px;
        }

        .nav {
            a {
                &:last-child {
                    margin-right: 0;
                    padding-right: 0;

                    &:before {
                        display: none;
                    }
                }
            }
        }
    }
}

@include sm {
    .as-sort-bar {
        text-align: center;

        .nav {
            justify-content: center;
        }

        select {
            margin: 0 auto;
        }
    }
}
