.breadcumb-menu {
  max-width: 100%;
  padding: 0;
  margin: 23px 0 -0.5em 0;
  list-style-type: none;
  position: relative;
  li {
    display: inline-block;
    margin-right: 3px;
    padding-right: 5px;
    list-style: none;
    position: relative;

    &:after {
      content: '\f054';
      position: relative;
      margin-left: 10px;
      font-weight: 600;
      font-size: 15px;
      color: $white-color;
      font-family: $icon-font;
    }

    &:last-child {
      padding-right: 0;
      margin-right: 0;

      &:after {
        display: none;
      }
    }  
    
    // &:last-child {
    //   &,
    //   a,
    //   span {
    //     color: $theme-color;
    //   }
    // }
  }

  li,
  a,
  span {
    white-space: normal;
    color: inherit;
    word-break: break-word;
    font-weight: 400;
    font-size: 18px;
    color: $white-color;
  }
}

.breadcumb-title {
  font-size: 48px;
  font-weight: 600;
  line-height: 1.18;
  text-transform: uppercase;
  color: $white-color;
  margin: -0.2em 0 -0.2em 0;
}


.breadcumb-wrapper {
  background-color: $title-color;
  padding: 140px 0;
  text-align: center;
  overflow: hidden;
}

@include lg {
  .breadcumb-title {
    font-size: 42px;
  }
  .breadcumb-wrapper {
    padding: 120px 0;
  }
}

@include md {
  .breadcumb-wrapper {
    padding: 100px 0;
  }
  .breadcumb-menu {
    margin: 16px 0 -0.5em 0;
    li,
    a,
    span {
      font-size: 16px;
    }
  }
  .breadcumb-title {
    font-size: 38px;
  }
}

@include sm {
  .breadcumb-wrapper {
    padding: 80px 0;
  }
  .breadcumb-title {
    font-size: 34px;
  }
}