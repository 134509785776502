.banner-form {
  padding-left: 50px;
}

.banner-form {
  .banner-form-inner {
    padding: 40px;
    border-radius: 3px;
    -webkit-box-shadow: 0 10px 20px 0 rgba(10,27,95,.1);
    box-shadow: 0 10px 20px 0 rgba(10,27,95,.1);
  }
}
