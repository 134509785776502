/* Tab Menu 1 ---------------------------------- */ 
.tab-menu1 {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 40px;
    button {
        font-size: 14px;
        color: $title-color;
        font-weight: 500;
        text-transform: uppercase;
        border: none;
        background-color: $white-color;
        padding: 9.5px 30px;
        box-shadow: 0px 4px 10px rgba(107, 131, 174, 0.04);
        border-radius: 999px;
        transition: 0.4s ease-in-out;
        &:hover {
            color: $theme-color;
        }
        &.active {
            color: $white-color;
            background-color: $theme-color;
        }
    }
}

@include ml {
    .tab-menu1 {
        gap: 10px;
        button {
            padding: 9.5px 25px;
        }
    }
}

@include lg {  
    .tab-menu1 {
        margin-bottom: 30px;
        button {
            font-size: 13px;
            padding: 7px 17px;
        }
    }
}

/* Tab Menu 2 ---------------------------------- */ 
.tab-menu2 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px;
    position: relative;
    margin-bottom: -0.4em;
    button {
        font-size: 14px;
        color: $title-color;
        font-weight: 500;
        line-height: 1.556;
        text-transform: uppercase;
        border: none;
        padding: 0;
        background-color: transparent;
        transition: 0.4s ease-in-out;
        position: relative;
        &:before {
            content: 'New';
            background-color: $theme-color;
            color: $white-color;
            display: inline-block;
            font-family: $body-font;
            font-size: 14px;
            line-height: 1.5;
            border-radius: 3px;
            padding: 0px 7px;
            position: absolute;
            bottom: 0;
            right: 0;
            visibility: hidden;
            transform: scale(0);
            transition: 0.4s ease-in-out;
        }
        &:after {
            content: '';
            border-style: solid;
            border-width: 9px 9px 0 0;
            border-color: $theme-color transparent transparent transparent;
            position: absolute;
            bottom: 100%;
            right: 20px;
            visibility: hidden;
            transform: scale(0);
            transition: 0.4s ease-in-out;
        }
        &:hover {
            color: $theme-color;
        }
        &.active {
            color: $theme-color;
            &:before,
            &:after {
                transform: scale(1);
                visibility: visible;
            }
            &:before {
                bottom: calc(100% + 9px);
            }
            &:after {
                transition-delay: 0.1s;
            }
        }
    }
}

@include sm {
    .tab-menu2 {
        gap: 26px 20px;
    }
}