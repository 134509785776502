/* Feature 1 ---------------------------------- */
.feature-card {
    padding: 45px 30px;
    background-color: $white-color;
    box-shadow: 0px 4px 21px rgba(179, 193, 219, 0.15);
    border-radius: 10px;
    text-align: center;
    &-wrap {
        &:nth-child(even) {
            transform: translateY(24px);
        }
    }
    &_icon {
        margin-bottom: 25px;
        img {
            transition: 0.4s ease-in-out;
        }
    }
    &_title {
        font-size: 22px;
        margin-bottom: 12px;
        margin-top: -0.28em;
        font-weight: 600;
    }
    &_text {
        margin-bottom: -0.45em;
    }
    &:hover {
        .feature-card {
            &_icon {
                img {
                    transform: rotateY(180deg);
                }
            }
        }
    }
}

@include sm {
    .feature-card {
        &-wrap {
            &:nth-child(even) {
                transform: translateY(0);
            }
        }
    }
}

/* Feature 1 ---------------------------------- */
.feature-grid {
    padding: 60px 50px;
    border-radius: 10px;
    text-align: center;
    border: 2px solid #0B2950;
    transition: 0.4s ease-in-out;
    &_icon {
        @include equal-size-lineHeight(100px);
        background-color: $theme-color;
        border-radius: 50%;
        text-align: center;
        margin: 0 auto 40px auto;
        img {
            transition: 0.4s ease-in-out;
        }
    }
    &_title {
        font-size: 24px;
        margin-bottom: 14px;
        margin-top: -0.28em;
        font-weight: 600;
        color: $white-color;
    }
    &_text {
        margin-bottom: -0.48em;
        color: $light-color;
    }
    &:hover {
        background-color: #092141;
        .feature-grid {
            &_icon {
                img {
                    transform: rotateY(180deg);
                }
            }
        }
    }
}

@include lg {
    .feature-grid {
        padding: 45px 15px;
        &_icon {
            margin: 0 auto 30px auto;
        }
    }
}

@include sm {
    .feature-grid_text {
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
    }
}