select,
.form-control,
.form-select,
textarea,
input {
    height: 55px;
    padding: 0 25px 0 25px;
    padding-right: 45px;
    //border: 1px solid transparent;
    color: $body-color;
    background-color: $smoke-color;
    border-radius: 5px;
    font-size: 16px;
    width: 100%;
    font-family: $body-font;
	transition: 0.3s ease-in-out;

    &:focus {
        outline: 0;
        box-shadow: none;
        border-color: $theme-color;
        background-color: $smoke-color;
    }

    @include inputPlaceholder {
        color: $body-color;
    }
    &.style2 {
        border: 1px solid transparent;
        padding-left: 52px;
        background-image: none;
        ~ i {
            color: $theme-color;
            font-weight: 400;
            left: 25px;
            max-width: 16px;
        }
    }
    &.style3 {
        background-color: $white-color;
        border: none;
    }
}

.form-select,
select {
    display: block;
    width: 100%;
    line-height: 1.5;
    vertical-align: middle;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3E%3C/svg%3E");
    background-position: right 26px center;
    background-repeat: no-repeat;
    background-size: 16px 12px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
}

textarea.form-control,
textarea {
    min-height: 150px;
    padding-top: 16px;
    padding-bottom: 17px;
    border-radius: 5px;
}

.form-group {
    margin-bottom: var(--bs-gutter-x);
    position: relative;

    > i {
        display: inline-block;
        position: absolute;
        right: 25px;
        top: 19px;
        font-size: 16px;
        color: #93a5be;
        &.fa-envelope {
            padding-top: 1px;
        }
        &.fa-comment {
            margin-top: -2px;
        }
        &.fa-chevron-down {
            width: 17px;
            background-color: $white-color;
        }
    }

    &.has-label {
        > i {
            top: 50px;
        }
    }
}

[class*="col-"].form-group {
    > i {
        right: calc((var(--bs-gutter-x) / 2) + 25px);
    }
    .style2 {
        ~ i {
            left: calc((var(--bs-gutter-x) / 2) + 25px);
        }
    }
}

option {
    &:checked,
    &:focus,
    &:hover {
        background-color: $theme-color;
        color: $white-color;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

input[type="checkbox"] {
    visibility: hidden;
    opacity: 0;
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 0;
    display: none;

    &:checked {
        ~ label {
            &:before {
                content: "\f00c";
                color: $white-color;
                background-color: $theme-color;
                border-color: $theme-color;
            }
        }
    }

    ~ label {
        position: relative;
        padding-left: 30px;
        cursor: pointer;
        display: block;

        &:before {
            content: "";
            font-family: $icon-font;
            font-weight: 700;
            position: absolute;
            left: 0px;
            top: 3.5px;
            background-color: $white-color;
            border: 1px solid $border-color;
            height: 18px;
            width: 18px;
            line-height: 18px;
            text-align: center;
            font-size: 12px;
        }
    }
}

input[type="radio"] {
    visibility: hidden;
    opacity: 0;
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 0;
    display: none;

    ~ label {
        position: relative;
        padding-left: 30px;
        cursor: pointer;
        line-height: 1;
        display: inline-block;
        font-weight: 600;
        margin-bottom: 0;

        &::before {
            content: "\f111";
            position: absolute;
            font-family: $icon-font;
            left: 0;
            top: -2px;
            width: 20px;
            height: 20px;
            padding-left: 0;
            font-size: 0.6em;
            line-height: 19px;
            text-align: center;
            border: 1px solid $theme-color;
            border-radius: 100%;
            font-weight: 700;
            background: $white-color;
            color: transparent;
            transition: all 0.2s ease;
        }
    }

    &:checked {
        ~ label {
            &::before {
                border-color: $theme-color;
                background-color: $theme-color;
                color: $white-color;
            }
        }
    }
}

label {
    margin-bottom: 0.5em;
    margin-top: -0.3em;
    display: block;
    color: $title-color;
    font-family: $body-font;
    font-size: 16px;
}

textarea.is-invalid,
select.is-invalid,
input.is-invalid,
.was-validated input:invalid {
    border: 1px solid $error-color !important;
    background-position: right calc(0.375em + 0.8875rem) center;
    background-image: none;

    &:focus {
        outline: 0;
        box-shadow: none;
    }
}

textarea.is-invalid {
    background-position: top calc(0.375em + 0.5875rem) right
        calc(0.375em + 0.8875rem);
}

.row.no-gutters > .form-group {
    margin-bottom: 0;
}

.form-messages {
    display: none;

    &.mb-0 * {
        margin-bottom: 0;
    }

    &.success {
        color: $success-color;
        display: block;
    }

    &.error {
        color: $error-color;
        display: block;
    }

    pre {
        padding: 0;
        background-color: transparent;
        color: inherit;
    }
}
